import { tv, VariantProps } from '@/utils/tv'

export const quoteCard = tv({
  slots: {
    base: ['flex flex-col', 'rounded-3xl h-[432px] lg:h-[750px]'],
    wrapper: [
      'z-10',
      'w-full h-full',
      'flex flex-col justify-end',
      'bg-gradient-to-t from-black to-transparent to-[120%]',
      'p-[1.4rem] lg:p-10'
    ],
    body: ['flex flex-col gap-5'],
    author: ['flex gap-5 mt-[1.4rem] lg:mt-[2.5rem]', 'flex-col'],
    authorDetails: ['flex flex-col gap-1 h-full'],
    name: ['not-italic'],
    role: [],
    bgImage: ['w-full h-full object-center object-cover', 'rounded-3xl']
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white',
        role: 'text-gray-20'
      },
      light: {
        base: 'text-black',
        role: 'text-gray-60'
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    appearance: 'ghost'
  }
})

export type QuoteCardVariantProps = VariantProps<typeof quoteCard>
