import { useMemo } from 'react'
import { BlockProps, Media } from '@/types'
import { QuoteCardVariantProps, quoteCard } from './quote-card.variants'

export type QuoteCard = {
  id: number
  name: string
  role: string
  description: string | null
  avatar: Media
}

export type useQuoteCardProps = BlockProps<QuoteCard, QuoteCardVariantProps>

export const useQuoteCard = (props: useQuoteCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    data: { avatar, ...restData }
  } = props

  const styles = useMemo(() => quoteCard({ theme }), [theme])

  return {
    theme,
    className,
    sectionName,
    data: {
      avatar,
      ...restData
    },
    styles
  }
}
