import { tv, VariantProps } from '@/utils/tv'

export const sliderCard = tv({
  slots: {
    base: ['relative w-full h-full'],
    wrapper: ['max-w-7xl', 'mx-auto', 'lg:px-8', 'h-full'],
    content: ['grid md:grid-cols-2', 'h-full'],
    caption: [
      'flex flex-col lg:justify-center gap-3',
      'px-6 py-10 md:py-20 lg:px-0 lg:py-28'
    ],
    title: ['max-w-md'],
    subtitle: ['md:max-w-md'],
    description: ['md:max-w-md'],
    cta: ['mt-4 flex-start'],
    media: [
      'flex flex-col justify-center',
      'h-full relative md:bg-transparent'
    ],
    media_container: [
      'aspect-w-16 aspect-h-12 sm:aspect-h-9 lg:aspect-none',
      'lg:h-full'
    ],
    image: ''
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white',
        title: 'text-acqua-500',
        description: 'text-gray-40',
        media: 'bg-gray-10'
      },
      light: {
        base: 'text-black',
        title: 'text-acqua-900',
        description: 'text-gray-60',
        media: 'bg-gray-900'
      }
    },
    appearance: {
      default: {},
      card: {},
      pro: {
        content: ['bg-gray-900', 'md:px-20', 'rounded-2xl'],
        base: ['text-white'],
        title: ['text-white'],
        subtitle: ['text-white']
      }
    },
    background: {
      cover: {
        image: 'object-cover'
      },
      contain: {
        image: 'object-contain'
      }
    },
    fixed: {
      true: {
        caption: ['min-h-[390px] sm:min-h-[340px] md:min-h-[auto]']
      }
    },
    type: {
      invalid: '',
      image: '',
      video: ''
    }
  },
  defaultVariants: {
    background: 'contain',
    theme: 'dark',
    type: 'image',
    fixed: false
  },
  compoundVariants: [
    {
      appearance: 'card',
      type: 'video',
      className: {
        media_container: 'md:hidden'
      }
    },
    {
      appearance: ['default', 'card'],
      caption: []
    }
  ]
})

export type SliderCardVariantProps = VariantProps<typeof sliderCard>
