'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { ReviewCard } from '@/components/ui/review-card'
import { QuoteCard } from '@/components/ui/quote-card'
import { useReviewOrQuoteCarouselProps } from './use-review-or-quote-carousel'

export type ReviewOrQuoteCarouselSwiperProps = Pick<
  useReviewOrQuoteCarouselProps,
  'className' | 'sectionName' | 'data'
>

const ReviewOrQuoteCarouselSwiper = ({
  className = '',
  sectionName,
  data
}: ReviewOrQuoteCarouselSwiperProps) => {
  const { theme, cardAppearance, reviews = [] } = data

  const isDefaultAppearance = cardAppearance !== 'pro'
  const ReviewComponent = isDefaultAppearance ? ReviewCard : QuoteCard

  return (
    <Swiper
      className={className}
      modules={[Autoplay]}
      dir="ltr"
      simulateTouch={false}
      spaceBetween={40}
      speed={800}
      slidesPerView={isDefaultAppearance ? 3 : 2}
      loop={!isDefaultAppearance}
      watchSlidesProgress
      autoplay={{
        delay: 3000,
        pauseOnMouseEnter: true,
        disableOnInteraction: true
      }}
      breakpoints={{
        0: {
          loop: true,
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {
          loop: true,
          slidesPerView: 2,
          spaceBetween: 20
        },
        1199: {
          loop: !isDefaultAppearance,
          slidesPerView: isDefaultAppearance ? 3 : 2,
          spaceBetween: isDefaultAppearance ? 40 : 20
        }
      }}
    >
      {reviews.map((review) => (
        <SwiperSlide key={`review-card-${review.id}`} className="!h-auto">
          <ReviewComponent
            sectionName={sectionName}
            theme={theme}
            data={review}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ReviewOrQuoteCarouselSwiper
